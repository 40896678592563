/**
 * @file SP 100vh
 *
*/

let vh = window.innerHeight * 0.01;

export default function () {
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}
