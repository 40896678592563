/**
 * @file アコーディオン
 *
*/

import { activeClass } from '../data/_variables';
const $window = $(window);

export default function () {

  const $accordion = $('.js-accordion');

  function responsiveAccordion() {
    $accordion.each((index, el) => {
      const $this = $(el);
      const $target = $this.next();
      const targetHeight = $target.children().outerHeight();
      if($this.hasClass(activeClass)) {
        $target.css('max-height',targetHeight);
      }
    });
  }

  $window.on('load resize',() => {
    responsiveAccordion();
  });

  $accordion.on('click', e => {

    // デフォルトの挙動を無効化
    e.preventDefault();

    const $this = $(e.currentTarget);
    const $target = $this.next();
    const targetHeight = $target.children().outerHeight();

    // data-accordionが指定されていたらグループ化
    const group = $this.attr('data-group');
    if(group){
      if($this.hasClass(activeClass)){
        $this.removeClass(activeClass);
        $target.removeAttr('style');
      } else {
        $('[data-group="'+group+'"]').removeClass(activeClass).next().removeAttr('style');
        $this.addClass(activeClass);
        $target.css('max-height',targetHeight);
      }
    } else {
      // クリックする度にclassを付与/除去
      $this.toggleClass(activeClass);
      // 次の要素にmax-heightを与える
      if ($this.hasClass(activeClass)) {
        if ($this.hasClass('p-item-detail__header')) {
          $target.css('max-height', 9999);
        } else {
          $target.css('max-height',targetHeight);
        }

        if ($this.parents('.l-header-menu__body').length) {
          console.log('yes');
          $this.parents('.l-header-menu__body').css('max-height', 9999);
        }
      } else {
        $target.removeAttr('style');
      }
    }
  });
}
