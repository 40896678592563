/**
 * @file スムーススクロール
 *
 */

import { activeClass, breakpointMd } from '../data/_variables';

const $scroll = $('.js-scroll');
const speed = 200; // スクロールの速度

export default function () {
  //クリックした時にスムーススクロール
  $scroll.on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const href = $this.attr('href');
    const $target = $(href === '#' || href === '' ? 'html' : href);

    scrollFunc($target);
    return false;
  });

  //ページ外アンカーの時の固定ヘッダーのズレ解消してスクロール
  const urlHash = location.hash;
  if (urlHash) {
    const $target = $(urlHash);
    $('body,html').stop().scrollTop(0);
    scrollFunc($target);
  }

  // 指定要素までアニメーションでスクロールさせる
  const smoothScroll = (position) => {
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
  };

  // スムーススクロールを実行
  const scrollFunc = ($target, position) => {
    // 移動先を数値で取得
    if (window.innerWidth < breakpointMd) {
      //SP指定
      const position = $target.offset().top;
      smoothScroll(position);
    } else {
      //PC指定
      const position = $target.offset().top;
      smoothScroll(position);
    }
  };
}
