/**
 * @file Newマーク
 *
 */

const $newsItem = $('.p-news__date');

export default function () {
  $newsItem.each(function () {
    // 現在日時
    var current = new Date();

    // 表示期間
    var period = 14;
    var period_cn = current.getTime() - period * 24 * 60 * 60 * 1000;

    // 投稿日
    var post = new Date($(this).attr('postdate'));
    var post_cn = post.getTime();

    if (period_cn < post_cn) {
      $(this).parent('.p-news__article').addClass('is-new');
    }
  });
}
