/**
 * @file page Top
 *
 */

import { activeClass } from '../data/_variables';

const pageTopButton = $('.l-footer__pagetop');

export default function () {
  $(window).on('load scroll', function () {
    var value = $(this).scrollTop();
    if (value > 100) {
      pageTopButton.addClass(activeClass);
    } else {
      pageTopButton.removeClass(activeClass);
    }
  });
}
