/**
 * @file pc Header スクロールで高さ変化
 *
 */

const $win = $(window);
const $header = $('.l-header, .l-footer__buttons');
const animationClass = 'is-animation';

export default function () {
  $win.on('load scroll resize', function () {
    if (window.matchMedia('(min-width: 896px)').matches) {
      var value = $(this).scrollTop();
      if (value > 100) {
        $header.addClass(animationClass);
      } else {
        $header.removeClass(animationClass);
      }
    }
  });
}
