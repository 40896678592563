/**
 * @file TOPページ カルーセル
 *
 */

import 'slick-carousel';
const $slider = $('.p-top-slider__list');

export default function () {
  if ($slider) {
    $slider.slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      fade: true,
      cssEase: 'ease-out',
      speed: 1000,
    });
  }
}
