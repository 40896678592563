import common from 'scripts/_common';
common();

/**
 * home画面でのみ使用する処理
 */

import topSlider from 'scripts/modules/topSlider';
topSlider();

import topics from 'scripts/modules/topics';
topics();
