/**
 * @file megamenu
 *
 */

const $menu = $('.p-header-menu__item');
const $megaMenu = $('.p-megamenu');

export default function () {
  if (window.matchMedia('(min-width: 896px)').matches) {
    $menu.hover(
      function () {
        $(this).find($megaMenu).show();
      },
      function () {
        $(this).find($megaMenu).hide();
      },
    );
  }
}
