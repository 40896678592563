/**
 * @file topics
 *
 */

const topicsClass = $('.p-topics');
const topicsBody = $('.p-topics__body');
const topicsOpen = $('.p-topics__open');
const topicsClose = $('.p-topics__close');

export default function () {
  if (topicsClass) {
    $(window).on('load', () => {
      topicsBody.slideDown(300);
    });

    topicsOpen.on('click', () => {
      topicsBody.slideDown(200);
    });
    topicsClose.on('click', () => {
      topicsBody.slideUp(200);
    });
  }
}
