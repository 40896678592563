/**
 * @file カレント表示
 *
 */

export default function () {
  const href = location.href;

  var links = document.querySelectorAll('.p-localnav__link');

  for (var i = 0; i < links.length; i++) {
    if (links[i].href == href) {
      document.querySelectorAll('.p-localnav__link')[i].classList.add('is-current');
    }
  }
}
