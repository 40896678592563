import 'core-js/stable';
import 'regenerator-runtime/runtime';
import scroll from 'scripts/modules/scroll.js';
import accordion from 'scripts/modules/accordion';
import current from 'scripts/modules/current';
import megamenu from 'scripts/modules/megamenu';
import newMark from 'scripts/modules/newMark';
import spMenu from 'scripts/modules/spMenu';
import pageTop from 'scripts/modules/pageTop';
import pcHeader from 'scripts/modules/pcHeader';
import vh from 'scripts/modules/vh';

// 共通処理を登録する
const common = () => {
  $(function () {
    accordion();
    current();
    megamenu();
    newMark();
    scroll();
    spMenu();
    pageTop();
    pcHeader();
    vh();

    // loading="lazy" 非対応ブラウザはlazysizesで対応
    if ('loading' in HTMLImageElement.prototype) {
      const images = document.querySelectorAll('img[loading="lazy"]');
      const img_srcset = document.querySelectorAll('img[data-srcset]');
      const sources = document.querySelectorAll('source[data-srcset]');

      if (images.length > 0) {
        images.forEach((img) => {
          if (img.dataset.src) {
            img.src = img.dataset.src;
          }
        });
      }
      if (img_srcset.length > 0) {
        img_srcset.forEach((img_srcset) => {
          img_srcset.srcset = img_srcset.dataset.srcset;
        });
      }
      if (sources.length > 0) {
        sources.forEach((source) => {
          source.srcset = source.dataset.srcset;
        });
      }
    } else {
      var script = document.createElement('script');
      script.src = '/assets/js/lazysizes.min.js';
      document.body.appendChild(script);
    }
  });
};

export default common;
