/**
 * @file SP menu
 *
 */

import { activeClass } from '../data/_variables';

var scrollpos;
const $menu = $('.js-menu');
const fixedClass = 'is-fixed';

const $toggle = $('.js-toggle');

export default function () {
  if (window.matchMedia('(max-width: 895px)').matches) {
    $menu.on('click', (event) => {
      // デフォルトの挙動を無効化
      event.preventDefault();

      const $this = $(event.currentTarget);

      if ($this.hasClass(activeClass)) {
        $('body').removeClass(fixedClass).css({ top: 0 });
        window.scrollTo(0, scrollpos);
        $menu.removeClass(activeClass);
      } else {
        scrollpos = $(window).scrollTop();
        $('body').addClass(fixedClass).css({ top: -scrollpos });
        $menu.addClass(activeClass);
      }
    });

    $toggle.on('click', (event) => {
      event.preventDefault();
      const $this = $(event.currentTarget);

      $this.next().slideToggle(200);
      $this.find('i').toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
    });
  }
}
